import React, { useEffect, useRef } from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../components/layout/layout';
import SEO from '../components/seo';
import { SmallerBlueArrow } from '../components/layout/arrows';
import Pagination from '../components/layout/pagination';
import Divider from '../components/pulse/divider';
import Snippet from '../components/pulse/snippets';
import Title from '../components/layout/title';

const ListingPulseTemplate = ({ data, pageContext }) => {
  const paginationRef = useRef();
  const { title, description, subtitle, currentPage, numPages, prefix, featuredItem } = pageContext;
  useEffect(() => {
    if (typeof window !== 'undefined' && currentPage > 1) {
      window?.scrollTo({
        behavior: 'smooth',
        top: paginationRef?.current?.getBoundingClientRect().top + window?.scrollY,
      });
    }
  }, []);

  return (
    <Layout>
      <SEO
        title={title || ''}
        description={description || ''}
        pathname={typeof window !== 'undefined' && location ? location.pathname : ''}
        image={data?.opengraph?.childImageSharp.resize || null}
      />
      <div className="bg-white overflow-x-hidden">
        <div className="relative max-w-7xl mx-auto px-5 xl:px-8">
          <section>
            <Title title={title || ''} border={false}>
              <p
                className="text-left text-sm font-light mb-4 text-gray-700 w-full max-w-3xl sm:text-xl sm:mb-5"
                dangerouslySetInnerHTML={{ __html: subtitle || '' }}
              />
            </Title>
          </section>
        </div>
        <div className="w-full bg-brand-blue-5 py-6 sm:py-12">
          <section>
            <div className="flex flex-col gap-5 max-w-7xl mx-auto px-5 lg:gap-10 lg:flex-row xl:px-8">
              <div className="flex flex-col my-auto w-full gap-2 lg:w-1/2">
                <span className="text-xs font-bold uppercase text-white tracking-widest">
                  Latest
                </span>
                <h3 className="text-left text-xl font-bold capitalize text-brand-green-3 sm:text-lg md:text-2xl lg:text-3xl sm:font-black">
                  {featuredItem.frontmatter.title}
                </h3>
                <span className="text-left text-xs italic font-light text-brand-blue-1">
                  {featuredItem.frontmatter.date_modified}
                </span>
                <span className="text-sm font-light text-brand-blue-1 mb-1 sm:mb-4">
                  {featuredItem.summary}
                </span>
                <Link
                  to={'/' + featuredItem.slug || ''}
                  className="hidden justify-center items-center w-max px-5 py-3.5 font-bold rounded-md bg-pos-0 scale-100 focus:border text-brand-blue-5 bg-brand-green-3 text-xs transition duration-200 uppercase hover:bg-gradient-to-r hover:from-brand-blue-4 hover:via-brand-blue-4 hover:to-brand-green-3 hover:bg-size-200 hover:border-transparent hover:bg-pos-100 hover:text-white hover:scale-105 focus:bg-brand-blue-5 focus:border-white focus:text-white focus:bg-none focus:scale-100 tracking-widest lg:flex"
                >
                  Read more
                </Link>
              </div>
              <Snippet node={featuredItem} />
              <Link
                to={'/' + featuredItem.slug || ''}
                className="flex justify-center items-center w-max px-5 py-3.5 font-bold rounded-md bg-pos-0 scale-100 focus:border text-brand-blue-5 bg-brand-green-3 text-xs transition duration-200 uppercase hover:bg-gradient-to-r hover:from-brand-blue-4 hover:via-brand-blue-4 hover:to-brand-green-3 hover:bg-size-200 hover:border-transparent hover:bg-pos-100 hover:text-white hover:scale-105 focus:bg-brand-blue-5 focus:border-white focus:text-white focus:bg-none focus:scale-100 tracking-widest lg:hidden"
              >
                Read more
              </Link>
            </div>
          </section>
        </div>
        <div className="w-full bg-brand-blue-1">
          <section>
            <div ref={paginationRef} className="flex flex-col gap-6 py-12">
              <div className="flex flex-col gap-6 items-center relative max-w-7xl mx-auto px-5 lg:pb-6 xl:px-8">
                {data?.allMdx?.nodes?.map((node, index) => (
                  <div key={`pulse_${index}`} className="flex flex-col gap-6">
                    <div className="flex flex-col gap-5 lg:flex-row lg:gap-10 xl:py-6">
                      <div className="flex flex-col my-auto w-full gap-2 lg:w-1/2">
                        <h3 className="text-left text-xl font-bold capitalize text-brand-blue-5 sm:text-lg md:text-2xl lg:text-3xl sm:font-black">
                          {node?.frontmatter.title}
                        </h3>
                        <span className="text-left text-xs italic font-light text-gray-700">
                          {node?.frontmatter.date_modified}
                        </span>
                        <span className="text-sm font-light text-gray-700 mb-1 sm:mb-4">
                          {node?.summary}
                        </span>
                        <Link
                          to={'/' + node?.slug || ''}
                          className="hidden justify-center items-center w-max px-5 py-3.5 font-bold rounded-md bg-pos-0 scale-100 focus:border text-white bg-brand-blue-4 text-xs transition duration-200 uppercase hover:bg-gradient-to-r hover:from-brand-blue-4 hover:via-brand-blue-4 hover:to-brand-green-3 hover:bg-size-200 hover:border-transparent hover:bg-pos-100 hover:text-white hover:scale-105 focus:bg-brand-blue-5 focus:border-white focus:text-white focus:bg-none focus:scale-100 tracking-widest lg:flex"
                        >
                          Read more
                        </Link>
                      </div>
                      <Snippet node={node} />
                      <Link
                        to={'/' + node?.slug || ''}
                        className="flex justify-center items-center w-max px-5 py-3.5 font-bold rounded-md bg-pos-0 scale-100 focus:border text-white bg-brand-blue-4 text-xs transition duration-200 uppercase hover:bg-gradient-to-r hover:from-brand-blue-4 hover:via-brand-blue-4 hover:to-brand-green-3 hover:bg-size-200 hover:border-transparent hover:bg-pos-100 hover:text-white hover:scale-105 focus:bg-brand-blue-5 focus:border-white focus:text-white focus:bg-none focus:scale-100 tracking-widest lg:hidden"
                      >
                        Read more
                      </Link>
                    </div>
                    <Divider />
                  </div>
                ))}
              </div>
              <Pagination currentPage={currentPage} numPages={numPages} prefix={prefix} />
            </div>
          </section>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query (
    $skip: Int!
    $limit: Int!
    $paths: [String!]!
    $listingType: String!
    $opengraph: String!
  ) {
    allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { frontmatter: { post_type: { eq: $listingType } }, slug: { in: $paths } }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        frontmatter {
          date_modified
          title
          direction_of_sf
          government_approval
        }
        id
        slug
        summary: excerpt(pruneLength: 200)
      }
    }
    opengraph: file(relativePath: { eq: $opengraph }) {
      id
      childImageSharp {
        gatsbyImageData
        resize(width: 1200) {
          src
          height
          width
        }
      }
    }
  }
`;

export default ListingPulseTemplate;
